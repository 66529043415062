import React from "react";

const Loading = () => (
  <div className="be-loading-active text-center">
    <div className="be-spinner">
      <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle fill="none" strokeWidth="4" strokeLinecap="round" cx="33" cy="33" r="30" className="circle"></circle>
      </svg>
    </div>
    <p className="h4 text-uppercase">Loading</p>
  </div>
)

export default Loading;