import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Button } from "react-bootstrap";
import { updateDateRange, fetchPageVideoMetrics } from "../../actions";
import { useRouteMatch } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";

const today = DateTime.local();
const sevenDaysAgo = today.minus({ days: 7 });
const fourteenDaysAgo = today.minus({ days: 14 });
const thirtyDaysAgo = today.minus({ days: 30 });
const firstDayOfMonth = today.startOf('month')

export const useLuxonDateRange = () => {
  const { start, end, label } = useSelector(state => state.dateRange);
  return {
    start: DateTime.fromISO(start),
    end: DateTime.fromISO(end),
    label
  }
}

export const useDateRangeUpdator = () => {
  const dispatch = useDispatch();
  return (metricsStart, start, end, label) => {
    start = DateTime.local(start.year, start.month, start.day);
    end = DateTime.local(end.year, end.month, end.day);
    metricsStart = DateTime.local(metricsStart.year, metricsStart.month, metricsStart.day);
    dispatch(updateDateRange(metricsStart.toISO(), start.toISO(), end.toISO(), label));
  }
}

export default () => {

  const { getTokenSilently } = useAuth0();
  const pageMatch = useRouteMatch("/pages/:pageID");
  const dispatch = useDispatch();
  const [token, setToken] = useState('');

  const { start, end, displayMessage } = useSelector(state => state.dateRange);

  const updater = useDateRangeUpdator();
  const updator = (newStart, newEnd, request = false) => {
    const diff = newEnd.diff(newStart, 'days').days;
    const metricsStartDate = newStart.minus({ days: diff });
    if (pageMatch && pageMatch.isExact && token && request) {
      dispatch(fetchPageVideoMetrics(token, metricsStartDate, newEnd, pageMatch.params.pageID));
    }

    updater(metricsStartDate, newStart, newEnd);
  }

  const [show, shouldShow] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        if (!isCancelled) setToken(token);
      };
      getToken();
    }

    return () => isCancelled = true;
  }, [getTokenSilently, setToken, token]);

  const toggle = (labelStr) => {
    if (show) {
      if (!labelStr) {
        updator(start, end, labelStr);
      }
    }
    shouldShow(!show);
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ fontSize: "16px" }}>
        Date Range: {displayMessage}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item style={{ fontSize: "14px" }} onClick={() => {
          updator(sevenDaysAgo, today, true);
          toggle("Last 7 Days");
        }}>Last Seven Day</Dropdown.Item>
        <Dropdown.Item style={{ fontSize: "14px" }} onClick={() => {
          updator(fourteenDaysAgo, today, true);
          toggle("Last 14 Days");
        }}>Last Two Weeks</Dropdown.Item>
        <Dropdown.Item style={{ fontSize: "14px" }} onClick={() => {
          updator(thirtyDaysAgo, today, true);
          toggle("Last 30 Days");
        }}>Last 30 Days</Dropdown.Item>
        <Dropdown.Item style={{ fontSize: "14px" }} onClick={() => {
          updator(firstDayOfMonth, today, true);
          toggle("Month to Date");
        }}>Month to Date</Dropdown.Item>

        <Dropdown.Divider style={{ marginBottom: "15px" }} />

        <div>
          <label style={{ marginLeft: "10px", marginRight: "10px", fontSize: "14px" }}>From:</label>
          <label style={{ marginRight: "10px" }}>
            <DatePicker
              filterDate={d => new Date() > d}
              selected={DateTime.fromISO(start).toJSDate()}
              selectsStart
              startDate={DateTime.fromISO(start).toJSDate()}
              endDate={DateTime.fromISO(end).toJSDate()}
              onChange={date => updator(DateTime.fromJSDate(date), DateTime.fromISO(end))}
            />
          </label>
        </div>

        <div>
          <label style={{ marginRight: "10px", marginLeft: "10px", fontSize: "14px" }}>To:</label>
          <label>
            <DatePicker
              filterDate={d => new Date() > d}
              selected={DateTime.fromISO(end).toJSDate()}
              selectsEnd
              startDate={DateTime.fromISO(start).toJSDate()}
              endDate={DateTime.fromISO(end).toJSDate()}
              minDate={DateTime.fromISO(start).toJSDate()}
              onChange={date => updator(DateTime.fromISO(start), DateTime.fromJSDate(date))}
            />
          </label>
        </div>
        <div className="text-center">
          <Button onClick={() => updator(DateTime.fromISO(start), DateTime.fromISO(end), true)} className="mx-auto" style={{ fontSize: "14px" }}>Update Range</Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}