import React from "react";
import { Row, Col } from "react-bootstrap";
import Loading from "./Loading";

const addCommas = (str) => {
  if (!str) {
    return "0";
  }
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Metric = (props) => {
  const metric = props.metric;
  let change = (((metric.periodIncrease - metric.comparingPeriodIncrease) / metric.comparingPeriodIncrease) * 100) || 0;
  if (metric.comparingPeriodIncrease === 0 && metric.periodIncrease !== 0) {
    change = 100;
  }
  const color = window.App.color.primary;
  let Indicator = () => (<i className="icon mdi mdi-caret-up mr-3" style={{ fontSize: "6rem" }}></i>);
  if (change < 0) {
    Indicator = () => (<i className="icon mdi mdi-caret-down mr-3" style={{ fontSize: "6rem" }}></i>);
  }

  if (metric.loading) {
    return (
      <Col className="d-flex flex-row justify-content-center">
        <div className={`widget widget-tile`}>
          <Loading />
        </div>
      </Col>
    )
  }

  return (
    <Col>
      <div className="widget widget-tile" style={{ display: "flex", justifyContent: "space-around" }}>
        <div className="data-info text-left">
          <div className="desc">{metric.name}</div>
          <div className="value" style={{ color: `${color}` }}>
            <span className="number" data-toggle="counter" data-end="113">{metric.isMoney ? `$${addCommas(metric.value.toFixed(2))}` : addCommas(metric.value.toString())}</span>
          </div>
          <div className="desc mt-3">% change</div>
          <div className="value" style={{ color: `${color}` }}>
            {change.toFixed(2)}%
          </div>
        </div>
        <div className="data-info d-flex align-items-center" id="spark1">
          {metric.indicator ? metric.indicator() : (<Indicator />)}
        </div>
      </div>
    </Col>
  )
}

export default (props) => {

  return (
    <Row className="justify-space-between">
      {props.metrics.map((metric, ndx) => (
        <Metric metric={metric} key={ndx} />
      ))}
    </Row>
  )
}