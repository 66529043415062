import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from '../react-auth0-spa';

const AuthGuard = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const login = async () => {
    await loginWithRedirect({
      connection_scope: 'pages_show_list,read_insights,pages_read_engagement',
      redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      appState: { targetUrl: window.location.pathname },
    });
  }

  return (
    <Route {...rest} render={
      props => {
        if (isAuthenticated) return <Component {...rest} {...props} />;
        login();
      }
    } />
  );
};

export default AuthGuard;