import React from 'react';
import { useAuth0 } from '../../react-auth0-spa';
import SearchByVideo from './SearchByVideo';
import outloudLogo from "../../images/OutLoudLogo.png";
import { Link } from 'react-router-dom';

const Header = () => {
  const { isAuthenticated, logout } = useAuth0();

  const renderContent = () => {
    if (isAuthenticated) {
      return (
        <>
          {/* <Link to="/pages">Pages</Link> */}
          {/* <Link to="/registerPage" style={{ marginLeft: '20px' }}>Register Page</Link> */}
          {/* <button onClick={() => logout()} style={{ marginLeft: '20px' }}>Log out</button> */}
          {/* <div className="search-container">Search By VideoId</div> */}
          <SearchByVideo />
          <div className="be-right-navbar search-container">
            <ul className="nav navbar-nav">
              <li className="nav-item">
                <button className="btn btn-warning" type="button" onClick={() => logout()}>Log Out</button>
              </li>
            </ul>
          </div>
        </>
      );
    }
  }

  return (
    <div>
      <nav className="navbar navbar-default fixed-top be-top-header">
        <div className="container-fluid d-flex justify-content-between">
          <div className="be-navbar-header">
            <Link className="nav-bar-brand" to="/">
              <img src={outloudLogo} alt="Outloud Media Logo" className="img-fluid px-5 ml-1" />
            </Link>
          </div>
          {renderContent()}
        </div>
      </nav>
    </div>
  );
}

export default Header;