import {
  UPDATE_PAGES,
  UPDATE_USER,
  UPDATE_PAGE_METRICS,
  UPDATE_PAGE_VIDEOS,
  UPDATE_VIDEOS,
  UPDATE_VIDEO_METRICS,
  UPDATE_ADMIN_PAGES,
  UPDATING_PAGES,
  FETCHING_VIDEO_METRICS,
  FETCHED_VIDEO_METRICS,
  FETCHING_USERS,
  FETCHED_USERS,
  FETCHING_RULE,
  FETCHED_RULE,
  CREATING_RULE,
  CREATED_RULE,
  UPDATING_USER,
  UPDATING_ADMIN_PAGES,
  UPDATE_DATE_RANGE,
} from '../actions/types';
import { DateTime } from 'luxon';

export const errorReducer = (state = { error: null }, action) => {
  if (!action.error) {
    return state;
  }

  return {
    ...state,
    error: {
      errorMessage: action.payload.message,
      response: action.payload,
    },
  };
}

export const userReducer = (state = { isLoading: true }, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...action.payload,
        isLoading: false,
      };
    case UPDATING_USER:
      return {
        ...state,
        isLoading: true,
      }
    default:
      return state;
  }
};

export const pagesListReducer = (state = { pages: [], isLoading: false }, action) => {
  switch (action.type) {
    case UPDATING_PAGES:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_PAGES:
      return {
        pages: action.payload.pagesList,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const pagesMetricsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PAGE_METRICS:
      return {
        ...state,
        [action.payload.pageId]: action.payload.metrics,
      };
    default:
      return state;
  }
};

export const pagesVideosReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PAGE_VIDEOS:
      return {
        ...state,
        [action.payload.pageId]: action.payload.videosList,
      };
    default:
      return state;
  }
};

export const videosReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_VIDEOS:
      return {
        ...state,
        [action.payload.videoId]: action.payload.video,
      };
    default:
      return state;
  }
};

export const videosMetricsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_VIDEO_METRICS:
      return {
        ...state,
        [action.payload.videoId]: action.payload.metrics,
      };
    default:
      return state;
  }
};

export const adminPagesListReducer = (state = { pages: [] }, action) => {
  switch (action.type) {
    case UPDATING_ADMIN_PAGES:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_ADMIN_PAGES:
      return {
        pages: action.payload.adminPagesList,
        isLoading: false,
      }
    default:
      return state;
  }
}

export const pageVideosMetricsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCHING_VIDEO_METRICS:
      return {
        ...state,
        [action.payload.pageID]: {
          metrics: null,
          ...(state[action.payload.pageID] || {}),
          isLoading: true,
        }
      }
    case FETCHED_VIDEO_METRICS:
      if (action.payload.metrics === null) {
        return state;
      }
      console.log("action.payload", action.payload)
      return {
        ...state,
        [action.payload.pageID]: {
          isLoading: false,
          metrics: action.payload.metrics.metrics,
          dateRange: action.payload.dateRange,
        }
      }
    default:
      return state;
  }
}

export const usersReducer = (state = { isFetching: false, users: [], loaded: false }, action) => {
  switch (action.type) {
    case FETCHING_USERS:
      return {
        ...state,
        isFetching: true,
      }
    case FETCHED_USERS:
      return {
        ...state,
        isFetching: false,
        users: action.payload.users,
        loaded: true,
      }
    default:
      return state;
  }
}

export const rulesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCHING_RULE:
      return {
        ...state,
        [action.payload]: {
          isFetching: true,
          rules: [],
        }
      }

    case FETCHED_VIDEO_METRICS:
      return {
        ...state,
        [action.payload.pageID]: {
          isFetching: false,
          rules: action.payload.metrics.rules,
        }
      }

    case FETCHED_RULE:
      return {
        ...state,
        [action.payload.resourceID]: {
          isFetching: false,
          rules: action.payload.data ? action.payload.data.rules : [],
        }
      }

    case CREATING_RULE:
      return {
        ...state,
        [action.payload]: {
          ...(state[action.payload] || {}),
          isFetching: true,
        }
      }

    case CREATED_RULE: {
      const newArray = [...state[action.payload.pageID].rules];
      newArray.push(action.payload.data && action.payload.data.rule);
      return {
        ...state,
        [action.payload.pageID]: {
          isFetching: false,
          rules: newArray,
        }
      }
    }

    default:
      return state;
  }
}

export const dateRange = (state = null, action) => {

  if (state === null) {
    const end = DateTime.local();
    const start = end.minus({ days: 7 });
    const metricsStartDate = start.minus({ days: 7 });
    state = {
      end: end.toISO(),
      start: DateTime.local(start.year, start.month, start.day).toISO(),
      metricsStart: metricsStartDate.toISO(),
      label: "7 Days Ago",
      displayMessage: "Last 7 days"
    }
  }
  if (action.type === UPDATE_DATE_RANGE) {
    const today = DateTime.local();
    const simpleEnd = DateTime.local(DateTime.fromISO(action.endDate).year, DateTime.fromISO(action.endDate).month, DateTime.fromISO(action.endDate).day);
    const isEndDateToday = simpleEnd.year === today.year && simpleEnd.month === today.month && simpleEnd.day === today.day;
    const simpleStart = DateTime.local(DateTime.fromISO(action.startDate).year, DateTime.fromISO(action.startDate).month, DateTime.fromISO(action.startDate).day);

    const diff = simpleEnd.diff(simpleStart, 'days').days;
    const message = isEndDateToday && diff <= 7 ?
      `Last ${diff} days` :
      `${action.startDate.substring(0, 10)} to ${action.endDate.substring(0, 10)}`;

    return {
      end: action.endDate,
      start: action.startDate,
      metricsStart: action.metricsStartDate,
      label: action.label,
      displayMessage: message,
    }
  }
  return state;
}