import SidePagesList from './SidePagesList';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../../react-auth0-spa';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../actions/index';

const Sidebar = () => {
  const { getTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [userFetched, setUserFetched] = useState(false);
  const canRegisterPage = useSelector(state => state.canRegisterPage.canRegisterPage);

  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        if (!isCancelled) setToken(token);
      };
      getToken();
    }

    return () => isCancelled = true;
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled
      && !userFetched
      && token
    ) {
      dispatch(fetchUser(token));
      setUserFetched(true);
    }

    return () => isCancelled = true;
  }, [token, dispatch, userFetched]);

  const renderRegisterButton = () => {
    if (canRegisterPage) {
      return (
        <li className="sub-menu">
          <Link to="/registerPage">
            <i className="icon mdi mdi-collection-plus"></i>
            <span>Register Page</span>
          </Link>
        </li>
      );
    }
  };

  return (
    <div className="be-left-sidebar">
      <div className="left-sidebar-wrapper">
        <div className="left-sidebar-spacer">
          <div className="left-sidebar-scroll">
            <div className="left-sidebar-content">
              <ul className="sidebar-elements">
                <li className="divider">Menu</li>
                <SidePagesList />
                {renderRegisterButton()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;