import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '../react-auth0-spa';

import Loading from "./Loading";
import { fetchRules } from "../actions/index";
import RuleCreateModal from "./RuleCreateModal";
import EditRuleModal from "./EditRuleModal";

export default (props) => {
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();
  const rules = useSelector(state => state.rules[props.resourceID]);
  const [showingModal, showModal] = useState(false);
  const [selectedRule, setSelectedRule] = useState(undefined);

  useEffect(() => {
    if (!rules && token) {
      dispatch(fetchRules(token, props.resourceID));
    }
  }, [props.resourceID, dispatch, rules, token]);

  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        if (!isCancelled) setToken(token);
      };
      getToken();
    }
    return () => isCancelled = true;
  }, [getTokenSilently, token])

  if (!rules || rules.isFetching) {
    return <Loading />
  }
  return (
    <>
      <table className="table table-stripped table-borderless">
        <thead>
          <tr className="text-uppercase">
            <th className="text-center">User</th>
            <th className="text-center">Rev Share</th>
            <th className="text-center">Amount to Recoup</th>
          </tr>
        </thead>
        <tbody className="no-border-x font-pt">
          {rules.rules.map((rule) => {
            return (
              <tr key={rule.userID} onClick={() => setSelectedRule(rule)}>
                <td className="text-center">{rule.user.nickname}</td>
                <td className="text-center">{rule.revShare * 100}%</td>
                <td className="text-center">${rule.recoupCosts}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="row justify-content-center">
        <div className="col-6 text-center my-5">
          {rules.rules.length === 0 && <p className="h4 font-weight-bolder">No rules for this page</p>}
          <button className="btn btn-primary btn-large" onClick={() => showModal(true)}>Create New Rule</button>
        </div>
      </div>
      <RuleCreateModal open={showingModal} onClose={() => showModal(false)} resourceID={props.resourceID} />
      <EditRuleModal open={!!selectedRule} rule={selectedRule} onClose={() => setSelectedRule(undefined)} resourceID={props.resourceID} />
    </>
  )
}