import React, { useEffect, useRef } from "react";
import $ from "jquery";

export default (props) => {
  let moris = useRef(null);
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && props.metrics) {
      const metrics = props.metrics;
      if (moris.current !== null) {
        // Destroy the old chart, then create the new one
        $(`#${props.id}`).empty();
      }
      moris.current = new window.Morris.Line({
        element: props.id,
        data: metrics,
        xkey: 'period',
        xLabels: 'day',
        // ykeys: ['3s Views', '10s Views'],
        ykeys: props.keys.map((key) => key.key),
        // labels: ['3s Views', '10s Views'],
        labels: props.keys.map((key) => key.label),
        // trendLineColors: [color1, color2],
        trendLineColors: props.keys.map((key) => key.color),
        lineColors: props.keys.map((key) => key.color),
        xLabelFormat: props.xLabelFormat || ((x) => x.toISOString().substring(5, 10)),
        continuousLine: false,
      });
    }

    return () => isCancelled = true;

  }, [props.metrics, props.id, props.keys, props.xLabelFormat]);
  return <div key={props.id} id={props.id} style={{ height: '250px' }}></div>
}