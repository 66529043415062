import { useAuth0 } from '../../react-auth0-spa';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MessageModal from '../Modal';

const SearchByVideo = () => {
  const { getTokenSilently } = useAuth0();
  const [token, setToken] = useState('');
  const [videoId, setVideoId] = useState(null);
  const [pageId, setPageId] = useState(null);

  const handleClick = async () => {
    if (!videoId || videoId.length === 0) {
      return;
    }

    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/${videoId}/pageInfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setPageId(res.data.pageId);
    } catch (error) {
      setPageId('');
    }
  }

  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        if (!isCancelled) setToken(token);
      };
      getToken();
    }

    return () => isCancelled = true;
  });

  if (pageId === '') {
    const message = `Could not find video ${videoId}`;
    return <MessageModal message={message} redirectTo="/" />;
  }

  if (pageId) {
    window.location = `/pages/${pageId}/videos/${videoId}`;
  }

  return (
    <div className="search-container">
      <div className="input-group input-group-sm">
        <input className="form-control search-input" type="text" name="search" onChange={(e) => setVideoId(e.target.value)} placeholder="Search by Video Id..." />
        <span className="input-group-btn">
          { token && 
            <button className="btn btn-primary" type="button" onClick={handleClick}>Search</button>
          }
        </span>
      </div>
    </div>
  )
}

export default SearchByVideo;