import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Row } from "react-bootstrap";
import { useAuth0 } from '../react-auth0-spa';
import { fetchUsers, deleteRule } from "../actions/index"

export default (props) => {
  const users = useSelector(state => state.users);
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        if (!isCancelled) setToken(token);
      };
      getToken();
    }
    return () => isCancelled = true;
  }, [token, dispatch, getTokenSilently]);
  useEffect(() => {
    if (props.rule === undefined || users === undefined) {
      return;
    }
    console.log("RULE:", props.rule);
    console.log("Resource:", props.resourceID)
  }, [props.rule, users, props.resourceID]);
  useEffect(() => {
    if (token && !users.loaded && !users.isFetching && props.open) {
      dispatch(fetchUsers(token))
    }
  }, [token, dispatch, users, props.open]);

  return (
    <Modal
      show={props.open}
      onHide={props.onClose}
      backdrop={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-danger">
        <Modal.Title>Delete Rule</Modal.Title>
      </Modal.Header>

      <Modal.Body >
        {props.rule !== undefined && (<Row>
          <p>Delete this Rule?</p>
        </Row>)}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>Close</Button>
        <Button variant="danger" onClick={() => {
          dispatch(deleteRule(token, props.rule, props.resourceID));
          props.onClose();
        }}>Delete Rule</Button>
      </Modal.Footer>
    </Modal>
  )
}
