import React, { useState, useEffect } from 'react';
import { useAuth0 } from '../react-auth0-spa';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminPages } from '../actions/index';
import MessageModal from './Modal';
import axios from 'axios';
import Loading from './Loading';

const PageRegister = () => {
  const { getTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();
  const adminPagesList = useSelector(state => state.adminPagesList);
  const [token, setToken] = useState('');
  const [pageRegisterResult, setPageRegisterResult] = useState(null);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        if (!isCancelled) setToken(token);
      };
      getToken();
    }
    return () => isCancelled = true;
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled
      && token
    ) dispatch(fetchAdminPages(token));

    return () => isCancelled = true;
  }, [token, dispatch]);

  const registerPage = async (e) => {
    setIsProcessingRequest(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_HOST}/registerPage`,
        { pageId: e.target.dataset.add, userName: user.name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setIsProcessingRequest(false);
      setPageRegisterResult(res.data.message);
    } catch (error) {
      setIsProcessingRequest(false);
      setPageRegisterResult(error.response.data.message);
    }
  }

  const updatePage = async (e) => {
    setIsProcessingRequest(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER_HOST}/updatePage`,
        { pageId: e.target.dataset.update, userName: user.name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setIsProcessingRequest(false);
      setPageRegisterResult(res.data.message);
    } catch (error) {
      setIsProcessingRequest(false);
      setPageRegisterResult(error.response.data.message);
    }
  }

  if (pageRegisterResult) {
    return <MessageModal message={pageRegisterResult} redirectTo="/registerPage" />;
  }

  const renderPagesToRegister = () => {
    return (
      <tbody>
        {
          adminPagesList.pages.map(page => {
            return (
              <tr key={page.id}>
                <td>{page.name}</td>
                <td>{page.id}</td>
                <td>{page.registeredAt.substring(0, 10)}</td>
                <td>{page.registeredByUserName}</td>
                <td>
                  {
                    token && user && page.registeredByUserName &&
                    <span className="icon" style={{ cursor: 'pointer' }} onClick={updatePage}>
                      <i className="mdi mdi-edit" data-update={page.id}></i>
                    </span>
                  }
                </td>
                <td>
                  {
                    token && user &&
                    <span className="icon" style={{ cursor: 'pointer' }} onClick={registerPage}>
                      <i className="mdi mdi-plus-square" data-add={page.id}></i>
                    </span>
                  }
                </td>
              </tr>
            );
          })
        }
      </tbody>
    );
  };

  if (isProcessingRequest) {
    return <div className="main-content container-fluid"><Loading /></div>;
  }

  return (
    <div>
      <div className="page-head">
        <h2 className="page-head-title">Register Page</h2>
      </div>
      <div className="main-content container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card card-table">
              <div className="card-header">Pages</div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-2">Page Name</th>
                      <th className="col-2">Page ID</th>
                      <th className="col-2">Registered On</th>
                      <th className="col-2">Registered By</th>
                      <th className="col-2">Update Access Token</th>
                      <th className="col-2">Register Page</th>
                    </tr>
                  </thead>
                  {adminPagesList.isLoading ? (
                    null
                  ) : renderPagesToRegister()}
                </table>
                {adminPagesList.isLoading ? (
                  <div className="d-flex flex-row justify-content-center my-5"><Loading /></div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageRegister; 