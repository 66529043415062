import React, { useState, useEffect } from "react";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useAuth0 } from '../react-auth0-spa';
import { fetchUsers, createNewRule } from "../actions/index"


export default (props) => {
  const rules = useSelector(state => state.rules[props.resourceID]);
  const users = useSelector(state => state.users);
  const rulesAreDisabled = rules && rules.isFetching;
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();
  const [selectedUser, selectUser] = useState(null);
  const [recoupCost, setRecoupCost] = useState(0);
  const [revSharePercent, setRevSharePercent] = useState(0);

  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        if (!isCancelled) setToken(token);
      };
      getToken();
    }
    return () => isCancelled = true;
  }, [token, dispatch, getTokenSilently]);
  useEffect(() => {
    if (token && !users.loaded && !users.isFetching && props.open) {
      dispatch(fetchUsers(token))
    }
  }, [token, dispatch, users, props.open]);
  return (
    <Modal
      show={props.open}
      onHide={props.onClose}
      backdrop={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Create a New Rule</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>User</Form.Label>
              <Typeahead
                id={props.resourceID}
                onChange={(selected) => {
                  selectUser(selected[0])
                }}
                options={users.users}
                labelKey="nickname"
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Revenue Share (%)</Form.Label>
              <Form.Control type="number" placeholder="Enter Revenue Share (0-100)" value={revSharePercent} onChange={(e) => {
                const newValue = +e.target.value;
                if (newValue >= 0 && newValue <= 100) {
                  setRevSharePercent(newValue);
                }
              }}
              />
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Amount to Recoup</Form.Label>
              <Form.Control type="number" placeholder="Amount to Recoup" value={recoupCost} onChange={(e) => {
                const newValue = +e.target.value;
                if (newValue >= 0) {
                  setRecoupCost(newValue);
                }
              }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>Close</Button>
        <Button variant="primary" disabled={rulesAreDisabled || !selectedUser || !revSharePercent} onClick={() => {
          dispatch(createNewRule(token, {
            user_id: selectedUser.user_id,
            resource_id: props.resourceID,
            rev_share: revSharePercent / 100,
            type: "page",
            recoup_costs: recoupCost,
            user: selectedUser,
          }));
          setRevSharePercent(0);
          setRecoupCost(0);
          props.onClose();
        }}>Create New Rule</Button>
      </Modal.Footer>
    </Modal>
  )
}