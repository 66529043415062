import React from "react";
import Graph from "./Graph";
import Loader from "./Loading";
import { useLuxonDateRange } from "./nav/DatePicker";

const PageGraphs = (props) => {
  const { start } = useLuxonDateRange();
  if (props.isLoading || !props.metrics) {
    return <Loader />
  }
  if (!props.metrics[start.toUTC().startOf("day").toISO()]) {
    props.metrics[start.toUTC().startOf("day").toISO()] = [];
    props.metrics[start.toUTC().startOf("day").toISO()].break = true;
  }

  const metrics = Object.keys(props.metrics).reduce((acc, date) => {
    // exclude comparing-period metrics
    if (date === "isLoading" || date < start.toISO()) {
      return acc;
    }
    if (props.metrics[date].break === true) {
      acc.push({
        threeSecViewCPM: null,
        dailyCPM: null,
        dailyImpressions: null,
        total3SecViews: null,
        total10SecViews: null,
        total60SecViews: null,
        period: date.substring(0, 10),
      });
      return acc;
    }
    acc.push(props.metrics[date].reduce((acc, rawMetric) => {
      const metric = rawMetric.calculatedMetrics || {
        daily3SecViewCPM: 0,
        dailyCPM: 0,
        dailyImpressionsPerView: 0,
        dailyTotal3SecViews: 0,
        dailyTotal10SecViews: 0,
        dailyTotal60SecViews: 0,
      };
      return {
        threeSecViewCPM: acc.threeSecViewCPM + metric.daily3SecViewCPM,
        dailyCPM: acc.dailyCPM + metric.dailyCPM,
        dailyImpressions: acc.dailyImpressions + metric.dailyImpressionsPerView,
        total3SecViews: acc.total3SecViews + metric.dailyTotal3SecViews,
        total10SecViews: acc.total10SecViews + metric.dailyTotal10SecViews,
        total60SecViews: acc.total60SecViews + (metric.dailyTotal60SecViews || 0),
        period: date.substring(0, 10),
      }
    }, {
      threeSecViewCPM: 0,
      dailyCPM: 0,
      dailyImpressions: 0,
      total3SecViews: 0,
      total10SecViews: 0,
      total60SecViews: 0,
      period: date.substring(0, 10),
    }));
    return acc;
  }, []);
  return (
    <Graph
      id={props.id}
      keys={[
        { key: "total3SecViews", label: "3s Views", color: window.App.color.primary },
        { key: "total10SecViews", label: "10s Views", color: window.tinycolor(window.App.color.primary).lighten(15).toString() },
        { key: "total60SecViews", label: "1 Minute Views", color: window.tinycolor(window.App.color.success).lighten(20).toString() }
      ]}
      metrics={metrics}
    />
  )
}

export default PageGraphs;