import { combineReducers } from 'redux';
import {
  pagesListReducer,
  userReducer,
  pagesMetricsReducer,
  pagesVideosReducer,
  videosReducer,
  videosMetricsReducer,
  errorReducer,
  adminPagesListReducer,
  pageVideosMetricsReducer,
  usersReducer,
  rulesReducer,
  dateRange,
} from './reducers';

export default combineReducers({
  pagesList: pagesListReducer,
  pagesMetrics: pagesMetricsReducer,
  pagesVideos: pagesVideosReducer,
  videos: videosReducer,
  videosMetrics: videosMetricsReducer,
  errorData: errorReducer,
  adminPagesList: adminPagesListReducer,
  canRegisterPage: userReducer,
  pageVideoMetrics: pageVideosMetricsReducer,
  users: usersReducer,
  rules: rulesReducer,
  dateRange: dateRange,
});