export default (start, metricsBucketedByDate, isFetching) => {
  const acc = {
    "10SecViews": {
      periodIncrease: 0,
      comparingPeriodIncrease: 0,
      name: "10 Second Views",
      good: "up",
      value: 0,
      loading: true,
      isMoney: false,
    },
    "3SecViews": {
      periodIncrease: 0,
      comparingPeriodIncrease: 0,
      name: "3 Second Views",
      good: "up",
      value: 0,
      loading: true,
      isMoney: false,
    },
    "60SecViews": {
      periodIncrease: 0,
      comparingPeriodIncrease: 0,
      name: "1 Minute Views",
      good: "up",
      value: 0,
      loading: true,
      isMoney: false,
    },
    revenue: {
      periodIncrease: 0,
      comparingPeriodIncrease: 0,
      name: "Revenue",
      good: "up",
      value: 0,
      loading: true,
      isMoney: true,
    },
    creators: {
      periodIncrease: 0,
      comparingPeriodIncrease: 0,
      name: "Creator's Cut",
      good: "up",
      value: 0,
      loading: true,
      isMoney: true,
    },
  }

  if (metricsBucketedByDate && !isFetching) {
    let totalMetrics = 0;
    Object.keys(metricsBucketedByDate).forEach((key) => {
      if (metricsBucketedByDate[key].length === 0) {
        return;
      }


      console.log("key", key, "start", start);
      if (key < start) {
        // add to comparing period
        metricsBucketedByDate[key].forEach(metric => {
          const calculatedMetrics = metric.calculatedMetrics || {
            daily3SecViewCPM: 0,
            dailyCPM: 0,
            dailyImpressionsPerView: 0,
            dailyTotal3SecViews: 0,
            dailyTotal10SecViews: 0,
            dailyTotal60SecViews: 0,
          }
          acc["10SecViews"].comparingPeriodIncrease += calculatedMetrics.dailyTotal10SecViews;
          acc["3SecViews"].comparingPeriodIncrease += calculatedMetrics.dailyTotal3SecViews;
          acc["60SecViews"].comparingPeriodIncrease += calculatedMetrics.dailyTotal60SecViews || 0;
          acc.revenue.comparingPeriodIncrease += metric.dailyMetrics.dailyEarnings;
          acc.creators.comparingPeriodIncrease += metric.dailyMetrics.dailyEarnings;
        })
      } else {
        // add to chosen period
        metricsBucketedByDate[key].forEach(metric => {
          const calculatedMetrics = metric.calculatedMetrics || {
            daily3SecViewCPM: 0,
            dailyCPM: 0,
            dailyImpressionsPerView: 0,
            dailyTotal3SecViews: 0,
            dailyTotal10SecViews: 0,
            dailyTotal60SecViews: 0,
          }
          acc["10SecViews"].periodIncrease += calculatedMetrics.dailyTotal10SecViews;
          acc["3SecViews"].periodIncrease += calculatedMetrics.dailyTotal3SecViews;
          acc["60SecViews"].periodIncrease += calculatedMetrics.dailyTotal60SecViews || 0;
          acc.revenue.periodIncrease += metric.dailyMetrics.dailyEarnings;
          acc.creators.periodIncrease += metric.dailyMetrics.dailyEarnings;
        })
      }

    })

    console.log("total:", totalMetrics);

    acc["10SecViews"].value = acc["10SecViews"].periodIncrease;
    acc["3SecViews"].value = acc["3SecViews"].periodIncrease;
    acc["60SecViews"].value = acc["60SecViews"].periodIncrease;
    // acc.revenue.value = Math.abs(acc.revenue.periodIncrease - acc.revenue.comparingPeriodIncrease) / 100;
    acc.revenue.value = acc.revenue.periodIncrease / 100;
    acc.creators.value = acc.creators.periodIncrease / 100;

    acc["10SecViews"].loading = false;
    acc["60SecViews"].loading = false;
    acc.revenue.loading = false;
    acc.creators.loading = false;
    acc["3SecViews"].loading = false;
  }

  return Object.values(acc);
}