import React, { useCallback, useState, useEffect } from "react";
import { useAuth0 } from '../react-auth0-spa';
import { Modal, Button, Row, Col } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { DateTime } from "luxon"
import { reconcile } from "../actions";

export default (props) => {
  const [start, setStart] = useState(DateTime.local().toISO());
  const [end, setEnd] = useState(DateTime.local().minus({ days: 30 }).toISO());
  const [error, setError] = useState(null);
  const { getTokenSilently } = useAuth0();
  const [token, setToken] = useState('');
  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        if (!isCancelled) setToken(token);
      };
      getToken();
    }

    return () => isCancelled = true;
  });

  const reconcilePage = useCallback(async () => {
    if (token === "") {
      return
    }
    setError(null);
    const resp = await reconcile(token, props.pageID, end, start);
    setError(resp);
    return resp;
  }, [start, end, props.pageID, token]);

  if (token === "") {
    return null;
  }

  return (
    <Modal
      show={props.open}
      onHide={props.onClose}
      backdrop={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="bg-primary">
        <Modal.Title>Reconcile {props.pageName}</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingBottom: "10em" }}>
        <Row className="py-10">
          <Col>
            <DatePicker
              filterDate={d => new Date() > d}
              selected={DateTime.fromISO(start).toJSDate()}
              selectsStart
              startDate={DateTime.fromISO(start).toJSDate()}
              endDate={DateTime.fromISO(end).toJSDate()}
              onChange={date => setStart(DateTime.fromJSDate(date).toISO())}
            />
          </Col>
          <Col>
            <p className="text-center">to</p>
          </Col>
          <Col>
            <DatePicker
              filterDate={d => new Date() > d}
              selected={DateTime.fromISO(end).toJSDate()}
              selectsStart
              startDate={DateTime.fromISO(start).toJSDate()}
              endDate={DateTime.fromISO(end).toJSDate()}
              onChange={date => setEnd(DateTime.fromJSDate(date).toISO())}
            />
          </Col>
        </Row>
        {error && (<Row>{JSON.stringify(error)}</Row>)}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>Close</Button>
        <Button onClick={async () => {
          const err = await reconcilePage();
          if (err === null) {
            props.onClose();
          }
        }}>Reconcile</Button>
      </Modal.Footer>
    </Modal>
  )
}