import axios from 'axios';
import {
  UPDATE_PAGES,
  UPDATE_USER,
  UPDATE_PAGE_METRICS,
  UPDATE_PAGE_VIDEOS,
  UPDATE_VIDEO_METRICS,
  UPDATE_ADMIN_PAGES,
  API_ERROR,
  UPDATING_PAGES,
  FETCHING_VIDEO_METRICS,
  FETCHED_VIDEO_METRICS,
  CREATING_RULE,
  CREATED_RULE,
  FETCHED_USERS,
  FETCHING_USERS,
  FETCHING_RULE,
  FETCHED_RULE,
  UPDATING_USER,
  UPDATING_ADMIN_PAGES,
  UPDATE_DATE_RANGE,
} from './types';
import { setup } from 'axios-cache-adapter'

const cachedGet = setup({
  baseURL: process.env.REACT_APP_SERVER_HOST,
  cache: {
    maxAge: 24 * 60 * 60 * 1000, // cache for one day!
    exclude: {
      query: false,
    }
  },
});

export const errorActionCreator = (error) => {
  return {
    type: API_ERROR,
    error: true,
    payload: error,
  };
};

export const fetchUser = (accessToken) => async dispatch => {
  dispatch({ type: UPDATING_USER });
  const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/user`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  });

  dispatch({ type: UPDATE_USER, payload: res.data });
};

export const fetchPages = (accessToken) => async dispatch => {
  dispatch({ type: UPDATING_PAGES });
  try {
    const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/pages`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    dispatch({ type: UPDATE_PAGES, payload: res.data });
  } catch (e) {
    console.error(e);
    dispatch({ type: UPDATE_PAGES, payload: {} });
  }

};

export const fetchPageMetrics = (accessToken, pageId) => async dispatch => {
  dispatch({ type: UPDATING_PAGES });
  try {
    const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/pages/${pageId}/metrics`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    dispatch({ type: UPDATE_PAGE_METRICS, payload: res.data });
  } catch (error) {
    dispatch(errorActionCreator(error.response.data));
  }
};

export const fetchPageVideos = (accessToken, pageId) => async dispatch => {
  dispatch({ type: UPDATING_PAGES });
  const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/pages/${pageId}/videos`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  dispatch({ type: UPDATE_PAGE_VIDEOS, payload: res.data });
};

export const fetchVideoMetrics = (accessToken, pageId, videoId) => async dispatch => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/${pageId}/videos/${videoId}/metrics`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    dispatch({ type: UPDATE_VIDEO_METRICS, payload: res.data });
  } catch (error) {
    dispatch(errorActionCreator(error.response.data));
  }
};

export const fetchAdminPages = (accessToken) => async dispatch => {
  dispatch({ type: UPDATING_ADMIN_PAGES });
  const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/adminPages`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  dispatch({ type: UPDATE_ADMIN_PAGES, payload: res.data });
};


export const fetchPageVideoMetrics = (accessToken, start, end, pageID) => async (dispatch) => {
  const startIso = start.toISOString ? start.toISOString() : start.toISO();
  const endIso = end.toISOString ? end.toISOString() : end.toISO();
  dispatch({ type: FETCHING_VIDEO_METRICS, payload: { pageID } });
  try {
    const res = await cachedGet.get(`/pages/${pageID}/videosMetrics`,
      {
        params: { from: startIso, to: endIso },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    // const metricsForTable = formatVideoMetricsData(res.data);
    dispatch({ type: FETCHED_VIDEO_METRICS, payload: { metrics: res.data, pageID, dateRange: { start, end } } })
  } catch (error) {
    console.error(error);
    dispatch({ type: FETCHED_VIDEO_METRICS, payload: { metrics: null, pageID } })
  }
}

export const createNewRule = (accessToken, ruleRequest) => async (dispatch) => {
  dispatch({ type: CREATING_RULE, payload: ruleRequest.resource_id });
  try {
    const res = await axios.post(`${process.env.REACT_APP_SERVER_HOST}/rules`,
      ruleRequest,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json"
        },
      },
    );
    dispatch({ type: CREATED_RULE, payload: { data: res.data, pageID: ruleRequest.resource_id } })
  } catch (error) {
    console.error(error);
    dispatch({ type: CREATED_RULE, payload: { data: null, pageID: ruleRequest.resource_id } })
  }
}

export const deleteRule = (accessToken, rule, resourceID) => async (dispatch) => {
  await axios.delete(`${process.env.REACT_APP_SERVER_HOST}/rules/${rule.resourceID}/${rule.userID}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  });
  dispatch(fetchRules(accessToken, resourceID));
}

export const fetchUsers = (accessToken) => async (dispatch) => {
  dispatch({ type: FETCHING_USERS });

  try {
    const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/users`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    // const metricsForTable = formatVideoMetricsData(res.data);
    dispatch({ type: FETCHED_USERS, payload: res.data })
  } catch (error) {
    console.error(error);
    dispatch({ type: FETCHED_USERS, payload: null })
  }
}

export const reconcile = async (accessToken, pageID, to, from) => {
  try {
    await axios.post(`${process.env.REACT_APP_SERVER_HOST}/pages/${pageID}/reconcile`,
      { to, from },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    return null;
  } catch (e) {
    if (e.response) {
      return e.response.data;
    }
    throw e;
  }
}

export const fetchRules = (accessToken, resourceID) => async (dispatch) => {
  dispatch({ type: FETCHING_RULE, payload: resourceID });
  try {
    const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/pages/${resourceID}/rules`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    // const metricsForTable = formatVideoMetricsData(res.data);
    dispatch({ type: FETCHED_RULE, payload: { data: res.data, resourceID } })
  } catch (error) {
    console.error(error);
    dispatch({ type: FETCHED_RULE, payload: { data: null, resourceID } })
  }
}

export const updateDateRange = (metricsStartDate, startDate, endDate, label) => (dispatch) => {
  dispatch({ type: UPDATE_DATE_RANGE, metricsStartDate, startDate, endDate, label });
}