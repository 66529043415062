import { BrowserRouter } from 'react-router-dom';
import Sidebar from './sidebar/Sidebar';
import Header from './nav/Header';
import Page from './Page';
import Video from './Video';
import PageRegister from './PageRegister';
import { useAuth0 } from "../react-auth0-spa";
import AuthGuard from './AuthGuard';
import { PageGuard, PageRegisterGuard } from './RouteGuards';
import React from 'react';
import IndexPage from "./IndexPage";

import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';

const App = () => {
  const { loading } = useAuth0();

  if (loading) {
    return (
      <div className="main-content container-fluid">
        <div className="be-loading-active text-center">
          <div className="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle fill="none" strokeWidth="4" strokeLinecap="round" cx="33" cy="33" r="30" className="circle"></circle>
            </svg>
          </div>
          <p className="h4 text-uppercase">Loading</p>
        </div>
      </div>
    );
  }

  return (
    <div className="be-wrapper be-fixed-sidebar">
      <BrowserRouter>
        <Header />
        <Sidebar />
        <div className="be-content">
          <AuthGuard Route exact path="/" component={IndexPage} />
          <AuthGuard Route exact path="/pages/:pageId" component={PageGuard(Page)} />
          <AuthGuard Route exact path="/pages/:pageId/videos/:videoId" component={PageGuard(Video)} />
          <AuthGuard Route exact path="/registerPage" component={PageRegisterGuard(PageRegister)} />
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;