export const UPDATE_PAGES = 'update_pages';
export const UPDATING_PAGES = "updating_pages";
export const UPDATE_USER = 'update_user';
export const UPDATING_USER = 'updating_user';
export const UPDATE_PAGE_METRICS = 'update_page_metrics';
export const UPDATE_PAGE_METRICS_ERROR = 'update_page_metrics_error';
export const UPDATE_PAGE_VIDEOS = 'update_page_videos';
export const UPDATE_VIDEOS = 'update_videos';
export const UPDATE_VIDEO_METRICS = 'update_video_metrics';
export const UPDATE_VIDEO_METRICS_ERROR = 'update_video_metrics_error';
export const UPDATE_ADMIN_PAGES = 'update_admin_pages';
export const UPDATING_ADMIN_PAGES = 'updating_admin_pages';
export const API_ERROR = 'api_error';
export const FETCHING_VIDEO_METRICS = "fetching_video_metrics";
export const FETCHED_VIDEO_METRICS = "fetched_video_metrics";
export const CREATING_RULE = "CREATING_RULE";
export const CREATED_RULE = "CREATED_RULE";
export const FETCHING_RULE = "FETCHING_RULE";
export const FETCHED_RULE = "FETCHED_RULE";
export const FETCHING_USERS = "FETCHING_USERS";
export const FETCHED_USERS = "FETCHED_USERS";
export const UPDATE_DATE_RANGE = "update_date_range";
