import { useAuth0 } from '../../react-auth0-spa';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPages } from '../../actions/index';
// import SidePageVideosList from './SidePageVideosList';
import { Link } from 'react-router-dom';

const SidePagesList = () => {
  const { getTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const pagesList = useSelector(state => state.pagesList.pages);
  const [token, setToken] = useState('');
  const [pagesFetched, setPagesFetched] = useState(false);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      window.App.init();
    }

    return () => isCancelled = true;
  });

  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        if (!isCancelled) setToken(token);
      };
      getToken();
    }

    return () => isCancelled = true;
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled
      && !pagesFetched
      && token
    ) {
      dispatch(fetchPages(token));
      setPagesFetched(true);
    }

    return () => isCancelled = true;
  }, [token, dispatch, pagesFetched]);

  const regExTest = useMemo(() => {
    return new RegExp(`.*${filter}.*`, "i");
  }, [filter,]);

  const renderPagesList = useCallback(() => {
    if (pagesFetched && pagesList.length > 0) {
      return (
        pagesList
          .filter((a) => {
            return regExTest.test(a.name);
          })
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (b.name < a.name) {
              return 1;
            }
            return 0;
          }).map(page => {
            return (
              <li key={page.id}>
                <Link to={{
                  pathname: `/pages/${page.id}`,
                  state: { pageName: page.name },
                }}>
                  {page.name}</Link>
              </li>
            )
          })
      );
    }
  }, [pagesList, pagesFetched, regExTest]);

  return (
    <>
      <li className="parent">
        <a href="/#">
          <i className="icon mdi mdi-flag"></i>
          <span>Pages</span>
        </a>
        {/* <ul className="sub-menu">
                    {
                        pagesList.map(page => {
                            return (
                                <SidePageVideosList key={page.id} pageId={page.id} pageName={page.name} />
                            )  
                        })
                    }
                </ul> */}
        <ul className="sub-menu">
          <li className="p-3">
            <input className="w-100" placeholder="Filter Pages" onInput={(e) => setFilter(e.target.value)} value={filter} />
          </li>
          {renderPagesList()}
        </ul>
      </li>
    </>
  );
};

export default SidePagesList;