import React, { useState, useEffect } from 'react';
import { useAuth0 } from '../react-auth0-spa';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPageVideoMetrics } from '../actions/index';
import MessageModal from './Modal';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import Rules from "./Rules";
import PageGraphs from './PageGraphs';
import MetricSnapshot from './MetricSnapshot';
import createSnapShot from '../utils/createSnapShot';
import { useDateRangeUpdator } from './nav/DatePicker';
import { DateTime } from 'luxon';
import GlobalDatePicker from './nav/DatePicker';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReconcileModal from "./reconcile";

const sumUpData = (rawMetrics, start) => {
  const resultObj = {};
  if (!rawMetrics) {
    return resultObj;
  }

  Object.values(rawMetrics).reduce((acc, metricPerDayArr) => {
    // exclude comparing-period metrics
    if (metricPerDayArr.length === 0 || metricPerDayArr[0].metricsDate < start) {
      return [...acc];
    }

    return [...acc, ...metricPerDayArr];
  }, []).forEach(metric => {
    const dMetric = metric.dailyMetrics;
    const cMetric = metric.calculatedMetrics || {
      daily3SecViewCPM: 0,
      dailyCPM: 0,
      dailyImpressionsPerView: 0,
      dailyTotal3SecViews: 0,
      dailyTotal10SecViews: 0,
      dailyTotal60SecViews: 0,
    };

    if (!resultObj[metric.videoId]) {
      resultObj[metric.videoId] = {
        videoId: metric.videoId,
        videoTitle: metric.videoTitle || '',
        videoCreatedAt: (metric.videoCreatedAt || '').substring(0, 10),
        total3SecViews: 0,
        total10SecViews: 0,
        total60SecViews: 0,
        totalEarnings: 0,
        totalImpressions: 0,
      };
    }

    resultObj[metric.videoId].total3SecViews += (cMetric.dailyTotal3SecViews || 0);
    resultObj[metric.videoId].total10SecViews += (cMetric.dailyTotal10SecViews || 0);
    resultObj[metric.videoId].total60SecViews += (cMetric.dailyTotal60SecViews || 0);
    resultObj[metric.videoId].totalEarnings += dMetric.dailyEarnings;
    resultObj[metric.videoId].totalImpressions += dMetric.dailyAdImpressions;
  })

  return resultObj;
}

const formatData = (rawMetrics, start) => {
  const sumUpMetrics = sumUpData(rawMetrics, start);
  const resultArr = [];
  Object.values(sumUpMetrics).forEach(metric => {
    resultArr.push({
      "Video": metric.videoId,
      "Publish Date": metric.videoCreatedAt,
      "Title": metric.videoTitle,
      "3s Views": metric.total3SecViews,
      "10s Views": metric.total10SecViews,
      "1 Minute Views": metric.total60SecViews,
      "Earnings": metric.totalEarnings,
      "Impressions": metric.totalImpressions,
      "CPM": metric.totalImpressions ? (metric.totalEarnings / metric.totalImpressions) * 1000 : 0,
      "CPM/View": metric.total3SecViews ? (metric.totalEarnings / metric.total3SecViews) * 1000 : 0,
    });
  })

  return resultArr;
}

const Page = (props) => {
  const userPerms = useSelector(state => {
    if (state.canRegisterPage.isLoading) {
      return [];
    }
    return state.canRegisterPage.permissions || [];
  });
  const today = DateTime.local();
  const sevenDaysAgo = today.minus({ days: 7 });
  const thirtyDaysAgo = today.minus({ days: 30 });
  const { getTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();
  const updator = useDateRangeUpdator();
  const [pageName, setPageName] = useState(null);
  const pagesMetrics = useSelector(state => state.pageVideoMetrics);
  const { start, end, metricsStart, displayMessage } = useSelector(state => state.dateRange);
  const rules = useSelector(state => state.rules);
  const [token, setToken] = useState('');
  const errorData = useSelector(state => state.errorData);
  const pageId = props.match.params.pageId;
  const [hasFetched, setHasFetched] = useState(false);
  const [snapshot, setSnapshot] = useState([]);
  const [dateRangeDidChange, setDidChange] = useState(false);
  const pageVideoMetrics = pagesMetrics[pageId] ? pagesMetrics[pageId] : { isLoading: true, metrics: [] };
  const pageRules = (rules[pageId] || { rules: [] }).rules.filter((rule) => {
    return userPerms.includes("read:rules") || rule.userID === user.sub;
  });

  const [isModalOpen, setModalOpen] = useState(false);

  const page = useSelector(state => {
    if (!state.pagesList || !state.pagesList.pages) {
      return {};
    }
    return state.pagesList.pages.find((page => page.id === pageId)) || {};
  });

  const getVideosMetrics = async (metricsStartDate, endIn) => {
    if (!metricsStartDate) {
      metricsStartDate = DateTime.fromISO(metricsStart);
    }
    if (!endIn) {
      endIn = DateTime.fromISO(end);
    }
    if (token) {
      dispatch(fetchPageVideoMetrics(token, metricsStartDate.toJSDate(), endIn.toJSDate(), pageId));
    }
  }

  useEffect(() => {
    const recoupCost = pageRules.reduce((acc, rule) => acc + rule.recoupCost, 0) || 0;
    const revShare = pageRules.reduce((acc, rule) => acc + rule.revShare, 0);

    setSnapshot(createSnapShot(start, pageVideoMetrics.metrics, pageVideoMetrics.isLoading).map((metric) => {
      if (metric.name === "Creator's Cut") {
        metric = {
          ...metric,
          comparingPeriodIncrease: (metric.comparingPeriodIncrease - recoupCost) * revShare,
          periodIncrease: (metric.periodIncrease - recoupCost) * revShare,
          value: (metric.value - recoupCost) * revShare,
        }
      }
      return metric;
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageVideoMetrics]);


  const handleWeek = () => {
    const metricsStartDate = sevenDaysAgo.minus({ days: 7 });
    updator(metricsStartDate, sevenDaysAgo, today);
    getVideosMetrics(metricsStartDate, today);
  };

  const handleMonth = () => {
    const metricsStartDate = thirtyDaysAgo.minus({ days: 30 });
    updator(metricsStartDate, thirtyDaysAgo, today);
    getVideosMetrics(metricsStartDate, today);
  };

  const setDateRange = (newStartDate, newEndDate) => {
    newStartDate = DateTime.fromJSDate(newStartDate);
    newEndDate = DateTime.fromJSDate(newEndDate);
    const diff = newEndDate.diff(newStartDate, 'days').days;
    const metricsStartDate = newStartDate.minus({ days: diff });
    updator(metricsStartDate, newStartDate, newEndDate);
  }

  useEffect(() => {
    let isCancelled = false;

    const getPageName = async () => {
      if (props.location.state && props.location.state.pageName) {
        setPageName(props.location.state.pageName);
      } else {
        try {
          const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/pages/${pageId}/metrics`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          setPageName(res.data.page.pageName);
        } catch (error) {
          console.error(error);
        }
      }
    }

    if (!isCancelled && token) {
      getPageName();
    }
    if (!isCancelled && token) {
      getVideosMetrics();
    }

    return () => isCancelled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, pageId]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && pageVideoMetrics) {
      $('#table1').dataTable().fnDestroy();

      $('#table1').dataTable({
        scrollX: true,
        data: formatData(pageVideoMetrics.metrics, start),
        columns: [
          {
            "data": 'Video',
            fnCreatedCell: function (nTd, sData) {
              $(nTd).html(`<a href="/pages/${pageId}/videos/${sData}">${sData}</a>`);
            }
          },
          { "data": 'Publish Date' },
          { "data": 'Title' },
          { "data": '3s Views' },
          { "data": '10s Views' },
          { "data": '1 Minute Views' },
          {
            "data": 'Earnings',
            render: function (data) {
              return "$" + (data / 100).toFixed(2);
            }
          },
          { "data": 'Impressions' },
          {
            "data": 'CPM',
            render: function (data) {
              return "$" + (data / 100).toFixed(2);
            }
          },
          {
            "data": 'CPM/View',
            render: function (data) {
              return "$" + (data / 100).toFixed(2);
            }
          },
        ],
        buttons: [
          'copy', 'csv', 'print'
        ],
        dom:
          "<'row be-datatable-header'<'col-sm-5'lf><'col-sm-7 text-right'B>>" +
          "<'row be-datatable-body'<'col-sm-12'tr>>" +
          "<'row be-datatable-footer'<'col-sm-5'i><'col-sm-7'p>>",
      });
    }

    return () => isCancelled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageVideoMetrics, pageId]);

  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        if (!isCancelled) setToken(token);
      };
      getToken();
    }

    return () => isCancelled = true;
  });

  useEffect(() => {
    let isCancelled = false;
    if (token && !isCancelled && !hasFetched && !pagesMetrics[pageId]) {
      dispatch(fetchPageVideoMetrics(token, DateTime.fromISO(metricsStart), DateTime.fromISO(end), pageId));
      setHasFetched(true);
    }

    return () => isCancelled = true;
  }, [pageId, token, dispatch, pagesMetrics, hasFetched, end, metricsStart]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setHasFetched(false);
    }

    return () => isCancelled = true;
  }, [pageId]);

  if (errorData.error) {
    return <MessageModal message={errorData.error.errorMessage} redirectTo="/pages" />;
  }


  const renderVideosTable = () => {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table">
            <div className="card-header">Video Metrics
                    <div style={{ float: "right" }}>
                <label style={{ marginLeft: "10px" }}>
                  <button className="dt-button buttons-alert" onClick={handleWeek}>Last Week</button>
                </label>

                <label style={{ marginLeft: "10px" }}>
                  <button className="dt-button buttons-alert" onClick={handleMonth}>Last Month</button>
                </label>

                <label style={{ marginLeft: "10px", marginRight: "10px" }}>From:</label>
                <label>
                  <DatePicker
                    filterDate={d => new Date() > d}
                    selected={DateTime.fromISO(start).toJSDate()}
                    selectsStart
                    startDate={DateTime.fromISO(start).toJSDate()}
                    endDate={DateTime.fromISO(end).toJSDate()}
                    onChange={date => {
                      setDidChange(true);
                      setDateRange(date, DateTime.fromISO(end).toJSDate())
                    }}
                  />
                </label>

                <label style={{ marginRight: "10px", marginLeft: "10px" }}>To:</label>
                <label>
                  <DatePicker
                    filterDate={d => new Date() > d}
                    selected={DateTime.fromISO(end).toJSDate()}
                    selectsEnd
                    startDate={DateTime.fromISO(start).toJSDate()}
                    endDate={DateTime.fromISO(end).toJSDate()}
                    minDate={DateTime.fromISO(start).toJSDate()}
                    onChange={date => {
                      setDidChange(true);
                      setDateRange(DateTime.fromISO(start).toJSDate(), date)
                    }}
                  />
                </label>
                <Button disabled={!dateRangeDidChange} onClick={() => {
                  // updator(DateTime.fromISO(start), DateTime.fromISO(end), true)
                  getVideosMetrics(DateTime.fromISO(start), DateTime.fromISO(end));
                  setDidChange(false);
                }} className="mx-auto" style={{ fontSize: "14px" }}>Update Range</Button>
              </div>
            </div>

            <div className="card-body">
              <table className="table table-striped table-hover table-fw-widget" id="table1">
                <thead>
                  <tr>
                    <th>Video</th>
                    <th>Publish Date</th>
                    <th>Title</th>
                    <th>3s Views</th>
                    <th>10s Views</th>
                    <th>1 Minute Views</th>
                    <th>Earnings</th>
                    <th>Impressions</th>
                    <th>CPM</th>
                    <th>CPM/View</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="page-head">
        <h2 className="page-head-title mb-5">
          {page.pagePictureUrl ? <img src={page.pagePictureUrl} alt="Page profile" className="img-fluid rounded-circle mr-3" style={{ width: "50px" }} /> : null}
          {pageName} {page.pageWarning ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="page-warning">{page.pageWarning}</Tooltip>
              }
            ><span className="text-warning mdi mdi-alert-triangle"></span></OverlayTrigger>

          ) : null}
          <Button className="ml-3" onClick={() => setModalOpen(true)}>Reconcile Page</Button>
          {userPerms.includes("edit:page") && (<Button variant="danger" className="ml-3" onClick={async () => {
            if (window.confirm(`Are you sure you want to delete ${pageName}?`)) {
              try {
                await axios.delete(`${process.env.REACT_APP_SERVER_HOST}/pages/${pageId}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  }
                });
                window.location.href = "/";
              } catch (e) {
                alert(JSON.stringify(e));
              }
            }
          }}>Delete Page</Button>)}
        </h2>

        <div className="row">
          <div className="col-md-9">
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb page-head-nav">
                <li className="breadcrumb-item">Pages</li>
                <li className="breadcrumb-item">{pageName}</li>
                <li className="breadcrumb-item active">Overview</li>
              </ol>
            </nav>
          </div>


          <div className="col-md-3">
            <div><GlobalDatePicker /></div>
          </div>

        </div>
      </div>
      <div className="main-content container-fluid">
        <MetricSnapshot metrics={snapshot} />
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header card-header-divider">
                <span className="title">{pageName} Video Views</span>
                <span className="card-subtitle">Daily 3s and 10s video views for the page from {displayMessage.toLowerCase()}</span>
              </div>

              <div className="card-body">
                <PageGraphs metrics={pageVideoMetrics.metrics} id={pageId} isLoading={pageVideoMetrics.isLoading} />
              </div>
            </div>
          </div>
        </div>
        {renderVideosTable()}
        {userPerms.includes("read:rules") && (
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-header card-header-divider">
                  <h2 className="h3">Page Rules</h2>
                </div>
                <div className="card-body">
                  <Rules resourceID={pageId} />
                </div>
              </div>
            </div>
          </div>)}
      </div>
      <ReconcileModal
        pageName={pageName}
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        pageID={pageId}
      />
    </>
  );
}

export default Page;
