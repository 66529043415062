import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';

const MessageModal = (props) => {
    const [show, setShow] = useState(true);
  
    const handleClose = () => {
        setShow(false);
        window.location = props.redirectTo;
    }

    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >

          <Modal.Header closeButton />

          <Modal.Body>
            <div className="text-center"><h3>{props.message}</h3></div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="btn btn-space btn-primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
export default MessageModal;