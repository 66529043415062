import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '../react-auth0-spa';
import axios from 'axios';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Graph from './PageGraphs';
import { Link } from 'react-router-dom';
import MetricSnapshot from './MetricSnapshot';
import createSnapShot from '../utils/createSnapShot';
import { DateTime } from "luxon";
import { useSelector } from 'react-redux';
import { useDateRangeUpdator } from './nav/DatePicker';
import GlobalDatePicker from './nav/DatePicker';

const formatData = (rawMetrics, start) => {
  const resultArr = [];
  Object.values(rawMetrics).forEach(metricArr => {
    // exclude comparing-period metrics
    if (metricArr.length === 0 || metricArr[0].metricsDate < start) {
      return;
    }
    metricArr.forEach(metric => {
      const dMetric = metric.dailyMetrics;
      const lMetric = metric.lifeMetrics;
      const cMetric = metric.calculatedMetrics;
      resultArr.push({
        "Video": metric.videoId,
        "Title": metric.videoTitle || '',
        "Publish Date": (metric.videoCreatedAt || '').substring(0, 10),
        "Metric Date": metric.metricsDate.substring(0, 10),
        "3s Views(D)": cMetric.dailyTotal3SecViews || 0,
        "10s Views(D)": cMetric.dailyTotal10SecViews || 0,
        "1 Minute Views(D)": cMetric.dailyTotal60SecViews || 0,
        "Earnings(D)": dMetric.dailyEarnings,
        "Impressions(D)": dMetric.dailyAdImpressions,
        "CPM(D)": cMetric.dailyCPM || 0,
        "CPM/View(D)": cMetric.daily3SecViewsCPM || 0,
        "3s Views(L)": lMetric.lifeTotal3SecViews || 0,
        "10s Views(L)": lMetric.lifeTotal10SecViews || 0,
        "1 Minute Views(L)": lMetric.lifeTotal60SecViews || 0,
        "Earnings(L)": lMetric.lifeEarnings,
        "Impressions(L)": lMetric.lifeAdImpressions,
        "CPM(L)": cMetric.lifeCPM || 0,
        "CPM/View(L)": cMetric.life3SecViewsCPM || 0,
      });
    })
  })

  return resultArr;
}

const Video = (props) => {
  const today = DateTime.local();
  const sevenDaysAgo = today.minus({ days: 7 });
  const thirtyDaysAgo = today.minus({ days: 30 });
  const { start, end, metricsStart, displayMessage } = useSelector(state => state.dateRange);
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  const updator = useDateRangeUpdator();

  const pageId = props.match.params.pageId;
  const videoId = props.match.params.videoId;
  const { getTokenSilently } = useAuth0();
  const [token, setToken] = useState('');
  const [pageName, setPageName] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);
  const [rawVideoMetrics, setRawVideoMetrics] = useState(null);
  const [isLoading, setIsLoanding] = useState(true);
  const [snapshot, setSnapshot] = useState(createSnapShot(start, null));

  const getVideoMetrics = useCallback(async (newStart, newEnd) => {
    const startDate = DateTime.fromISO(newStart || metricsStart);
    const endDate = DateTime.fromISO(newEnd || end);
    try {
      const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}/${pageId}/videos/${videoId}/metrics`,
        {
          params: { from: startDate.toISO(), to: endDate.toISO() },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return res.data;
    } catch (error) {
      return null;
    }
  }, [metricsStart, end, pageId, videoId, token]);

  const updateVideoMetrics = useCallback(async (metricsStartDate, end) => {
    setIsLoanding(true);
    setSnapshot(createSnapShot(start, null, true).filter((metric) => {
      return metric.name !== "Creator's Cut";
    }));

    let result = null;
    if (token) {
      result = await getVideoMetrics(metricsStartDate, end);
    }

    if (!result) {
      setRawVideoMetrics(null);
      setSnapshot(createSnapShot(start, null, false).filter((metric) => {
        return metric.name !== "Creator's Cut";
      }));
      setIsLoanding(false);
      return;
    }

    if (!pageName) { setPageName(result.pageName); }
    if (!videoTitle) { setVideoTitle(result.videoTitle); }

    setRawVideoMetrics(result.videoMetrics);
    setSnapshot(createSnapShot(start, result.videoMetrics, false).filter((metric) => {
      return metric.name !== "Creator's Cut";
    }));
    setIsLoanding(false);
  }, [token, start, getVideoMetrics, setIsLoanding, setPageName, setVideoTitle, setRawVideoMetrics, pageName, videoTitle]);

  const handleWeek = () => {
    const metricsStartDate = sevenDaysAgo.minus({ days: 7 });
    updator(metricsStartDate, sevenDaysAgo, today)
    updateVideoMetrics(metricsStartDate, today);
  };

  const handleMonth = () => {
    const metricsStartDate = thirtyDaysAgo.minus({ days: 30 });
    updator(metricsStartDate, thirtyDaysAgo, today)
    updateVideoMetrics(metricsStartDate, today);
  };

  const setDateRange = (newStartDate, newEndDate) => {
    newStartDate = DateTime.fromJSDate(newStartDate);
    newEndDate = DateTime.fromJSDate(newEndDate);
    const diff = newEndDate.diff(newStartDate, 'days').days;
    const metricsStartDate = newStartDate.minus({ days: diff });
    updator(metricsStartDate, newStartDate, newEndDate);
    updateVideoMetrics(metricsStartDate, newEndDate);
  }

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && token) {
      updateVideoMetrics();
    }

    return () => isCancelled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, videoId]);

  useEffect(() => {
    updateVideoMetrics(metricsStart, end);
  }, [metricsStart, end, updateVideoMetrics])

  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        if (!isCancelled) setToken(token);
      };
      getToken();
    }

    return () => isCancelled = true;
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && rawVideoMetrics) {
      const videoDataForTable = formatData(rawVideoMetrics, start);
      $('#table2').dataTable().fnDestroy();

      $('#table2').dataTable({
        scrollX: true,
        data: videoDataForTable,
        columns: [
          { "data": 'Video' },
          { "data": 'Title' },
          { "data": 'Publish Date' },
          { "data": 'Metric Date' },
          { "data": '3s Views(D)' },
          { "data": '10s Views(D)' },
          { "data": '1 Minute Views(D)' },
          {
            "data": 'Earnings(D)',
            render: function (data) {
              return Math.round(data);
            }
          },
          { "data": 'Impressions(D)' },
          {
            "data": 'CPM(D)',
            render: function (data) {
              return Math.round(data);
            }
          },
          {
            "data": 'CPM/View(D)',
            render: function (data) {
              return Math.round(data);
            }
          },
          { "data": '3s Views(L)' },
          { "data": '10s Views(L)' },
          { "data": '1 Minute Views(L)' },
          {
            "data": 'Earnings(L)',
            render: function (data) {
              return Math.round(data);
            }
          },
          { "data": 'Impressions(L)' },
          {
            "data": 'CPM(L)',
            render: function (data) {
              return Math.round(data);
            }
          },
          {
            "data": 'CPM/View(L)',
            render: function (data) {
              return Math.round(data);
            }
          },
        ],
        buttons: [
          'copy', 'csv', 'print'
        ],
        dom:
          "<'row be-datatable-header'<'col-sm-5'lf><'col-sm-7 text-right'B>>" +
          "<'row be-datatable-body'<'col-sm-12'tr>>" +
          "<'row be-datatable-footer'<'col-sm-5'i><'col-sm-7'p>>",
      });
    }

    return () => isCancelled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawVideoMetrics]);

  const renderVideoMetricsChart = () => {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header card-header-divider">
              <span className="title">Video {videoId}</span>
              <span className="card-subtitle">
                Daily 3s and 10s video views for the video from {displayMessage.toLowerCase()}
              </span>
            </div>

            <div className="card-body">
              <Graph metrics={rawVideoMetrics} id={videoId} isLoading={isLoading} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderVideoMetricsTable = () => {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="card card-table">
            <div className="card-header">Video Metrics
                    <div style={{ float: "right" }}>
                <label style={{ marginLeft: "10px" }}>
                  <button className="dt-button buttons-alert" onClick={handleWeek}>Last Week</button>
                </label>

                <label style={{ marginLeft: "10px" }}>
                  <button className="dt-button buttons-alert" onClick={handleMonth}>Last Month</button>
                </label>

                <label style={{ marginLeft: "10px", marginRight: "10px" }}>From:</label>
                <label>
                  <DatePicker
                    filterDate={d => new Date() > d}
                    selected={startDate.toJSDate()}
                    selectsStart
                    startDate={startDate.toJSDate()}
                    endDate={endDate.toJSDate()}
                    onChange={date => setDateRange(date, DateTime.fromISO(end).toJSDate())}
                  />
                </label>

                <label style={{ marginRight: "10px", marginLeft: "10px" }}>To:</label>
                <label>
                  <DatePicker
                    filterDate={d => new Date() > d}
                    selected={endDate.toJSDate()}
                    selectsEnd
                    startDate={startDate.toJSDate()}
                    endDate={endDate.toJSDate()}
                    minDate={startDate.toJSDate()}
                    onChange={date => setDateRange(DateTime.fromISO(start).toJSDate(), date)}
                  />
                </label>

              </div>
            </div>

            <div className="card-body">
              <table className="table table-striped table-hover table-fw-widget" id="table2">
                <thead>
                  <tr>
                    <th>Video</th>
                    <th>Title</th>
                    <th>Publish Date</th>
                    <th>Metric Date</th>
                    <th>3s Views(D)</th>
                    <th>10s Views(D)</th>
                    <th>1 Minute Views(D)</th>
                    <th>Earnings(D)</th>
                    <th>Impressions(D)</th>
                    <th>CPM(D)</th>
                    <th>CPM/View(D)</th>
                    <th>3s Views(L)</th>
                    <th>10s Views(L)</th>
                    <th>1 Minute Views(L)</th>
                    <th>Earnings(L)</th>
                    <th>Impressions(L)</th>
                    <th>CPM(L)</th>
                    <th>CPM/View(L)</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="page-head">
        <h2 className="page-head-title">{videoTitle || ''}</h2>

        <div className="row">
          <div className="col-md-9">
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb page-head-nav">
                <li className="breadcrumb-item">Pages</li>
                <li className="breadcrumb-item"><Link to={`/pages/${pageId}`}>{pageName || ''}</Link></li>
                <li className="breadcrumb-item">Videos</li>
                <li className="breadcrumb-item active">{videoId || ''}</li>
              </ol>
            </nav>
          </div>

          <div className="col-md-3">
            <div><GlobalDatePicker /></div>
          </div>
        </div>
      </div>

      <div className="main-content container-fluid">
        <MetricSnapshot metrics={snapshot} />
        {renderVideoMetricsChart()}
        {renderVideoMetricsTable()}
      </div>
    </>
  );
}

export default Video;