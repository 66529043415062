import React, { useEffect, useState } from "react";
import { useAuth0 } from '../react-auth0-spa';

import { useDispatch, useSelector } from 'react-redux';
import { fetchPageVideoMetrics } from "../actions";
import Graphs from "./PageGraphs";
import { Link } from "react-router-dom";
import Loader from "./Loading";
import MetricSnapshot from "./MetricSnapshot";
import createSnapShot from "../utils/createSnapShot";
import { DateTime } from "luxon";
import GlobalDatePicker from './nav/DatePicker';
import Pagination from "react-js-pagination";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const isSameDate = (date1, date2) => {
  return date1.year === date2.year && date1.month === date2.month && date1.day === date2.day;
}

export default () => {

  const userPerms = useSelector(state => {
    if (state.canRegisterPage.isLoading) {
      return [];
    }
    return state.canRegisterPage.permissions;
  });
  const { start, end, metricsStart, displayMessage } = useSelector(state => state.dateRange);
  const pagesIsLoading = useSelector(state => state.pagesList.isLoading)
  const pagesList = useSelector(state => state.pagesList.pages);
  const metrics = useSelector(state => state.pageVideoMetrics)
  const rules = useSelector(state => state.rules);
  const { getTokenSilently, user } = useAuth0();
  const [token, setToken] = useState("");
  const [snapshot, setSnapshot] = useState(createSnapShot(start, null));
  const dispatch = useDispatch();

  // pagination
  const itemsCountPerPage = 4;
  const lastPage = Math.ceil((pagesList.length || 0) / itemsCountPerPage);
  const [activePagesMetrics, setActivePagesMetrics] = useState(null);
  const [activePageList, setActivePageList] = useState([]);
  const [activePageNumber, setActivePageNumber] = useState(1);
  const [paginationPageList, setPaginationPageList] = useState([]);

  const handlePageChange = (pageNumber) => {
    setActivePageNumber(pageNumber);
    setActivePageList(paginationPageList[pageNumber - 1]);
  }

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && pagesList.length >= 0) {
      const paginationPageList = [];
      if (pagesList.length > 0) {
        for (let i = 0; i < pagesList.length; i++) {
          const index = Math.floor(i / itemsCountPerPage);
          if (!paginationPageList[index]) {
            paginationPageList[index] = [];
          }

          paginationPageList[index].push(pagesList[i]);
        }
      }
      setPaginationPageList(paginationPageList)
      setActivePageList(paginationPageList[activePageNumber - 1]);
    }
    return () => isCancelled = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesList]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && activePageList) {
      const updatedActiveMetrics = {};
      activePageList.forEach((page) => {
        updatedActiveMetrics[page.id] = metrics[page.id] || { isLoading: true };
      })
      setActivePagesMetrics(updatedActiveMetrics);
    }
    return () => isCancelled = true;
  }, [metrics, activePageList]);

  useEffect(() => {
    let isCancelled = false;
    if (!token) {
      const getToken = async () => {
        const token = await getTokenSilently();
        console.log("Token is:", token);
        if (!isCancelled) setToken(token);
      };
      getToken();
    }
    return () => isCancelled = true;
  }, [getTokenSilently, token]);

  useEffect(() => {
    if (activePagesMetrics) {
      //Check to see if any metrics are loading.
      const anyIsLoading = Object.values(activePagesMetrics).some((value) => value.isLoading) || Object.keys(metrics).length === 0;
      if (anyIsLoading) {
        setSnapshot(createSnapShot(start, null, true));
      }

      if (!anyIsLoading && activePagesMetrics) {
        setSnapshot(createSnapShot(start, null, false));
      }

      if (!anyIsLoading && activePagesMetrics) {
        const revenue = {};
        const zippedMetrics = Object.keys(activePagesMetrics || {}).reduce((acc, pageMetricKey) => {
          const pageMetric = activePagesMetrics[pageMetricKey];
          const singleSnap = createSnapShot(start, pageMetric.metrics);
          revenue[pageMetricKey] = singleSnap.find((metric) => metric.name === "Revenue");
          Object.keys(pageMetric.metrics).forEach((key) => {
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(...pageMetric.metrics[key]);
          });
          return acc;
        }, {});

        const creatorsCutSnapshot = Object.keys(revenue).reduce((acc, key) => {
          const pageRev = revenue[key];
          const pageRule = ((rules[key] || {}).rules || []).filter((rule) => {
            if (!userPerms) return false;
            return userPerms.includes("read:rules") || rule.userID === user.sub;
          });
          const recoupCost = pageRule.reduce((acc, rule) => acc + rule.recoupCost, 0) || 0;
          const revShare = pageRule.reduce((acc, rule) => acc + rule.revShare, 0);
          const comparingPeriodCost = pageRev.comparingPeriodIncrease - recoupCost;
          const periodCost = pageRev.periodIncrease - recoupCost;
          const valueCost = pageRev.value - recoupCost;
          acc.comparingPeriodIncrease += comparingPeriodCost * revShare;
          acc.periodIncrease += periodCost * revShare;
          acc.value += valueCost * revShare;
          return acc;
        }, { periodIncrease: 0, comparingPeriodIncrease: 0, value: 0 });

        setSnapshot(createSnapShot(start, zippedMetrics, false).map(metric => {
          if (metric.name === "Creator's Cut") {
            metric = {
              ...metric,
              ...creatorsCutSnapshot,
            }
          }
          return metric;
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePagesMetrics, rules]);

  useEffect(() => {
    if (activePageList) {
      activePageList.map((page) => {
        let oldStart = null;
        let oldEnd = null;
        const newStart = DateTime.fromISO(metricsStart);
        const newEnd = DateTime.fromISO(end);
        const oldStartStr = metrics[page.id] && metrics[page.id].dateRange && metrics[page.id].dateRange.start;
        const oldEndStr = metrics[page.id] && metrics[page.id].dateRange && metrics[page.id].dateRange.end;
        if (oldStartStr) {
          oldStart = DateTime.fromJSDate(oldStartStr);
        }
        if (oldEndStr) {
          oldEnd = DateTime.fromJSDate(oldEndStr);
        }
        const startDateChanged = !!oldStart && !isSameDate(oldStart, newStart);
        const endDateChanged = !!oldEnd && !isSameDate(oldEnd, newEnd);
        const isCurrentlyLoading = metrics[page.id] && metrics[page.id].isLoading;
        const dateChanged = startDateChanged || endDateChanged; //&& (!metrics[page.id] && !metrics[page.id].isLoading);
        if ((!metrics[page.id] || (dateChanged && !isCurrentlyLoading)) && token) {
          dispatch(fetchPageVideoMetrics(token, DateTime.fromISO(metricsStart).toJSDate(), DateTime.fromISO(end).toJSDate(), page.id));
        }
        return null;
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePageList, metricsStart, end, token]);

  if (pagesIsLoading) {
    return (
      <>
        <div className="page-head">
          <h1 className="page-head-title h2">Overview</h1>
          <nav aria-label="breadcrumb" role="navigation">
            <ol className="breadcrumb page-head-nav">
              <li className="breadcrumb-item">Pages</li>
            </ol>
          </nav>
        </div>
        <div className="">
          <div className="row justify-content-center">
            <Loader />
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="page-head">
        <h1 className="page-head-title h2">Overview</h1>

        <div className="row">
          <div className="col-md-9">
            <nav aria-label="breadcrumb" role="navigation">
              <ol className="breadcrumb page-head-nav">
                <li className="breadcrumb-item">Pages</li>
              </ol>
            </nav>
          </div>

          <div className="col-md-3">
            <div><GlobalDatePicker /></div>
          </div>
        </div>

      </div>
      <div className="main-content">
        <MetricSnapshot metrics={snapshot} />
        <div className="row">
          {activePageList && activePageList.map(page => {
            const pageMetric = metrics[page.id] || {};
            return (
              <div className="col-6" key={page.id}>
                <div className="card card-table">
                  <div className="card-header">
                    <h3 className="h4 text-center font-weight-bolder">
                      <Link to={{
                        pathname: `/pages/${page.id}`,
                        state: { pageName: page.name },
                      }}
                      >
                        {page.pagePictureUrl ? <img src={page.pagePictureUrl} alt="Page profile" className="img-fluid rounded-circle mr-2" style={{ width: "30px" }} /> : null}
                        {page.name}
                        {page.pageWarning ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="page-warning">{page.pageWarning}</Tooltip>
                            }
                          ><span className="text-warning mdi mdi-alert-triangle"></span></OverlayTrigger>

                        ) : null}</Link>
                      {pageMetric && !pageMetric.isLoading && pageMetric.metrics ? (<span className="card-subtitle mt-3">
                        Daily 3s and 10s video views for the page from {displayMessage.toLowerCase()}
                      </span>) : null}
                    </h3>
                  </div>
                  <div className="card-body table-responsive">{
                    pageMetric && !pageMetric.isLoading && pageMetric.metrics ? (
                      <Graphs metrics={pageMetric.metrics} id={page.id} />
                    ) : <Loader />
                  }</div>
                </div>
              </div>
            )
          })}
        </div>

        <div>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={activePageNumber}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={pagesList.length || 0}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
            lastPageText={`${lastPage}`}
            firstPageText="1"
          />
        </div>

      </div>
    </>
  )
}