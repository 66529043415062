import React from 'react';
import { useSelector } from 'react-redux';
import MessageModal from './Modal';
import Loading from './Loading';

export const PageRegisterGuard = (ChildComponent) => {
  const WithRegisterGuard = ({ ...props }) => {
    const canRegisterPage = useSelector(state => state.canRegisterPage.canRegisterPage);
    const isLoading = useSelector(state => state.canRegisterPage.isLoading);

    if (!isLoading) {
      if (canRegisterPage) {
        return <ChildComponent {...props} />;
      } else {
        const message = 'Insufficient permission to register page';
        return <MessageModal message={message} redirectTo="/" />;
      }
    }

    return <Loading />;
  }

  return WithRegisterGuard;
}

export const PageGuard = (ChildComponent) => {
  const WithPageGuard = ({ ...props }) => {
    const pagesList = useSelector(state => state.pagesList.pages);
    const pagesIsLoading = useSelector(state => state.pagesList.isLoading)
    const pageId = props.match.params.pageId;

    if (!pagesIsLoading) {
      const hasPagePermission = pagesList.some(pageObj => {
        return pageObj.id === pageId;
      });

      if (hasPagePermission) {
        return <ChildComponent {...props} />;
      } else {
        const message = `Page ${pageId} is inaccessible either due to insufficient permission or page is not registered yet.`
        return <MessageModal message={message} redirectTo="/" />;
      }
    }

    return <div className="main-content container-fluid"><Loading /></div>;
  }

  return WithPageGuard;
}
